import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// Theme
import Theme from 'theme'

// Componentes

// Pages
import HomePage from 'pages/home'

// utils
import RequireAuth from 'utils/require-auth'

import useAuthentication from 'hooks/useAuthentication'
import { GlobalProvider } from 'hooks/useGlobalContext'

const App = () => {
  const { user, loading, isAuthenticated, signOut, signIn } =
    useAuthentication()

  return (
    <Theme>
      <GlobalProvider
        value={{ user, loading, isAuthenticated, signOut, signIn }}
      >
        <Routes>
          <Route path='/' element={<HomePage />} />

          <Route
            path='/protected'
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          />

          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </GlobalProvider>
    </Theme>
  )
}

export default App
