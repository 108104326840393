import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from 'react-redux-i18n'
import { Auth } from 'aws-amplify'
import { getCookie } from 'react-use-cookie'

import translations from 'translations'

import { AUTH_SCOPE, AUTH_RESPONSE_TYPE, LANG_COOKIE_NAME } from './constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default (store) => {
  const prevLang = getCookie(LANG_COOKIE_NAME) || 'en'

  syncTranslationWithStore(store)
  store.dispatch(loadTranslations(translations))
  store.dispatch(setLocale(prevLang))

  configureAuthentication()
}

const configureAuthentication = () => {
  Auth.configure({
    userPoolId: 'us-east-1_XXXXXXXXX', // This won't be used, but needs to match Cognito User Pool ID format
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    oauth: {
      scope: AUTH_SCOPE,
      responseType: AUTH_RESPONSE_TYPE,
      domain: process.env.REACT_APP_BROKER_DOMAIN,
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    },
  })
}